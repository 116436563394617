import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import OSDViewer from '../components/OSDViewer';
import TranslatedText from '../components/TranslatedText';
import Layout from '../Layout';
import { hotspotsReadyState } from '../recoil/Atoms';
import {
  buttonsContainer,
  flexContainer,
  logo,
  mapMuralButton,
  mapMuralExplore,
  mapMuralHeading,
  mapMuralImage,
  subtitle,
  titleContainer,
  titleText,
} from './index.module.scss';

const IndexPage = ({ data, location }) => {
  //console.log(data);
  const [viewer, setViewer] = useState(null);
  const hotspotsReady = useRecoilValue(hotspotsReadyState);

  // All osds are queried in order to populate the page link buttons
  const osds = data?.allStrapiOsd?.nodes;

  // Filter the osds to get the background canvas
  const osd = osds.find(({ osd_id }) => {
    return osd_id === 'mural';
  });

  const [activeHotspot, setActiveHotspot] = useState('');

  // On the homepage, hotspots are used for animation only.
  // They have already been filtered in graphql
  const hotspots = data?.allStrapiHotspot?.nodes;

  // GSAP sets new activeHotspots. OSD handles the animation.
  const hotspotTlRef = useRef();

  useEffect(() => {
    const shuffledHotspots = hotspots.sort(() => Math.random() - 0.5);

    hotspotTlRef.current = gsap.timeline({ paused: true, repeat: -1 });
    shuffledHotspots.forEach((hotspot, index) => {
      hotspotTlRef.current.call(
        () => setActiveHotspot(hotspot.hotspot_id),
        null,
        index * 12
      );
    });
    hotspotsReady && hotspotTlRef.current.play();
  }, [hotspots, hotspotsReady]);

  return (
    <Layout location={location}>
      <OSDViewer
        osdId={osd.osd_id}
        pageId={'home'}
        xmlData={osd.xml_data}
        viewer={viewer}
        // TODO replace with hotspotsReadyState?
        setViewer={setViewer}
        hotspots={hotspots}
        activeCategory={'ALL_MURAL'} // make the hotspots visible
        activeHotspot={activeHotspot}
      />
      <div className={`${flexContainer}`}>
        <div className={`${titleContainer}`}>
          <h1 className={`${titleText}`}>
            <TranslatedText
              data={data.strapiInterface}
              name="home_title"
              className={`h1`}
              preserve_outer_p_tag
            />
          </h1>
          <div className={`${logo}`}>
            <StaticImage
              src="../images/camden-history-title.png"
              alt="Camden History"
            />
          </div>
          <h2 className={`${subtitle}`}>
            <TranslatedText
              data={data.strapiInterface}
              name="home_subtitle"
              className={`h2`}
              preserve_outer_p_tag
            />
          </h2>
          <div className={`${buttonsContainer}`}>
            {osds.map((osd) => (
              <Link
                className={`${mapMuralButton} `}
                to={`${osd.osd_id}`}
                key={osd.osd_id}
              >
                <GatsbyImage
                  image={getImage(osd.thumbnail.localFile)}
                  alt=""
                  className={`${mapMuralImage}`}
                  loading="eager"
                />
                <h3 className={`${mapMuralHeading}`}>
                  <span className="p block">
                    <TranslatedText data={osd} name="description" />
                  </span>
                  <TranslatedText data={osd} name="name" />
                </h3>
                <span className={`${mapMuralExplore} buttonTextAction`}>
                  <TranslatedText data={data.strapiInterface} name="explore" />
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiOsd(sort: { fields: [osd_id], order: [ASC] }) {
      nodes {
        name
        osd_id
        description
        thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
        xml_data {
          Image {
            xmlns
            Format
            Overlap
            TileSize
            Size {
              Height
              Width
            }
            Url
          }
        }
        localizations {
          data {
            attributes {
              locale
              name
              description
            }
          }
        }
      }
    }
    allStrapiHotspot(
      sort: { fields: [sort_order, title], order: [ASC, ASC] }
      filter: { osd: { osd_id: { eq: "mural" } } }
    ) {
      nodes {
        category
        hotspot_id
        hotspot_image {
          height
          width
          ext
          localFile {
            svgData
          }
        }
        osd {
          osd_id
        }
        x_position
        y_position
      }
    }
    strapiInterface {
      home_title
      home_subtitle
      explore
      localizations {
        data {
          attributes {
            locale
            home_title
            home_subtitle
            explore
          }
        }
      }
    }
  }
`;

export default IndexPage;
